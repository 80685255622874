.selected-work {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color-light);
  align-items: flex-start;
  padding-top: 5rem;
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
  width: 100vw;
  gap: 3rem;
}

.selected-work .title {
  font-size: 4rem;
  font-family: "Fraunces", serif;
  color: var(--primary-color-dark);
  padding-left: 2rem;
  margin-bottom: 2rem;
}

.selected-work .work-cards {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr)); */
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 3rem;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin: 0 auto;
  width: 100vw;
  padding-left: 2rem;
  padding-right: 2rem;

  > div:nth-child(8n + 1), > div:nth-child(8n + 6) {
    grid-column: span 2;
  }

  > div:nth-child(8n + 2), > div:nth-child(8n + 5) {
    grid-column: span 4;
  }

  > div:nth-child(8n + 3), > div:nth-child(8n + 4),  > div:nth-child(8n + 7),  > div:nth-child(8n) {
    grid-column: span 3;
  }
  
  @media only screen and (max-width: 50em) {
    grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
    > div:nth-child(n) {
      grid-column: span 1;
    }
  }
}





.selected-work .all-projects {
  all:unset;
  text-transform: uppercase;
  background-color: var(--highlight-coral);
  font-family: "Work Sans", sans-serif;
  border-radius: 30px;
  color: #fff;
  padding: 16px 36px;
  margin: 4rem;
  font-size: 24px;
  text-align: center;
  box-shadow: 0.5rem 0.5rem 1.2rem rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.selected-work .all-projects:hover {
  box-shadow: 0.75rem 0.75rem 1.2rem rgba(0, 0, 0, 0.2);
  cursor:pointer;
}

@media screen and (max-width: 50rem) {
  .selected-work .title {
    font-size: 3rem;
    padding-left: 1rem;
  }

  .selected-work .work-cards {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
}
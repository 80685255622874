.skill-card {
  color: var(--primary-color-dark);
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}


.skill-card .name {
  font-size: 2rem;
  color: var(--parimary-color-dark);
  font-family: "Fraunces", serif;

}

.skill-card p {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 4rem;
  background-color: var(--primary-color-light);
  position: relative;
  z-index: 10;
  max-width: 100vw;
}

.header .logo {
  font-size: 1rem;
  color: var(--primary-color-dark);
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.logo-link {
  text-decoration: none;
}

.logo-link:hover {
  text-decoration: underline;
  text-decoration-color: var(--primary-color-dark);
}

.desktop-nav ul {
  list-style: none;
  display: flex;
  gap: 4rem;
}

.desktop-nav ul li a {
  color: var(--primary-color-dark);
  font-size: 1rem;
  text-decoration: none;
  font-weight: 300;
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
}

.desktop-nav ul li a:hover {
  text-decoration: underline;
}

/* Hamburger Menu Styles */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1010;
}

.hamburger span {
  width: 2rem;
  height: 0.25rem;
  background: var(--primary-color-dark);
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.hamburger:hover span {
  box-shadow: 0 0 10px var(--primary-color-dark);
}

.hamburger.open span:first-child {
  transform: rotate(45deg);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1005;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
}

.mobile-nav {
  width: 100%;
}

.mobile-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-nav ul li {
  margin: 1rem 0;
}

.mobile-nav ul li a {
  color: var(--primary-color-dark);
  font-size: 24px;
  text-decoration: none;
  font-weight: 300;
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
}

.mobile-nav ul li a:hover {
  text-decoration: underline;
}

@media (max-width: 50rem) {
  .header {
    padding: 0 1.2rem;
  }

  .desktop-nav {
    display: none;
  }

  .hamburger {
    display: flex;
  }
}
.landingPage {
  height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
}

.background-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10; /* Ensure it's behind other content */
}

.hero {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: var(--primary-color-light);
}

.hero .title {
  font-size: 8rem;
  font-weight: 700;
  padding: 1rem;
  color: var(--primary-color-dark);
  font-family: "Fraunces", serif;
}

.hero .subtitle {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1.5rem;
  color: var(--primary-color-dark);
  font-family: "Work Sans", sans-serif;
  
}
.hero .see-more {
  padding: 1.5rem 0px;
  display: flex;
  flex: stretch;
  justify-content: flex-end;
  position:absolute;
  bottom: 1rem;
  right: 3rem;
  width: 100%;

}
.hero .see-more button{
  all: unset;
  color: var(--primary-color-dark);
  font-family: "Work Sans", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  display: flex;
  gap: 0.2rem;
  animation-name: bounce;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@media (prefers-reduced-motion: reduce) {
  .hero .see-more button {
    animation: none;
  }
}
.hero .see-more button:hover {
  text-decoration: underline;
}

@keyframes bounce {
  0% { 
    transform: translateY(0px) scale(1); 
  } 
  100% {
    transform: translateY(12px) scale(1);
  }
}
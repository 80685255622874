.core-skills {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 2rem;
  padding-top: 5rem;
  background-color: var(--primary-color-light);
  color: var(--primary-color-dark);
  font-family: "Work Sans", sans-serif;
  min-height: 80vh;
}

.core-skills .title {
  padding-left: 2rem;
  font-size: 4rem;
  font-family: "Fraunces", serif;
  align-self: flex-start;
  margin-bottom: 2rem;
}

.core-skills .skills-table {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 3rem;
  width: 100vw;
  box-sizing: border-box;
  padding-left: 2rem;
  padding-right: 2rem;
}

.core-skills .other-skills {
  font-size: 1.2rem;
  font-weight: 700;
}

@media screen and (max-width: 50rem) {
  .core-skills .title {
    font-size: 3rem;
    padding-left: 1rem;
  }

  .core-skills .skills-table {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
  
}
.about {
  display: flex;
  flex-direction: column;
  line-height: 1.4;
  background-color: var(--primary-color-light);
  color: var(--primark-color-dark);
  min-height: 80vh;
  max-width: 60rem;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  justify-content: center;
}

.about .title {
  font-size: 4rem;
  font-family: "Fraunces", serif;
}

.about .description {
  font-size: 2.5rem;
  font-family: "Fraunces", serif;
  text-align: left;
  font-weight: 400;
}

@media screen and (max-width: 50rem) {

  .about {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .about .title {
    font-size: 4rem;
  }

  .about .description {
    font-size: 1.8rem;
  }
  
}
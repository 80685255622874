.footer {
  padding: 3rem 1rem;
  margin-top: 1rem;
  text-align: center;
}

.footer h2 {
  font-size: 2rem;
  color: var(--primary-color-dark);
  margin-bottom: 1rem;
}

.footer .contacts {
  display: flex;
  justify-content: center;
  gap: 1.2rem;
}

.footer .contacts a {
  color: var(--primary-color-dark);
  text-decoration: none;
  font-weight: bold;
  padding: 0.5rem;
}

.footer .contacts a:hover {
  text-decoration: underline;
}
